<template>
  <div
    class="bg-white shadow-lift mb-10 border-tozny"
    :class="{ 'border-2': highlight }"
  >
    <div
      class="h-14 border-b-2 border-gray-light flex justify-between items-center px-4"
      v-if="titleBar"
    >
      <h3 class="font-semibold text-sm" v-if="title">{{ this.title }}</h3>
      <slot name="extras" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    showTitleBar: Boolean,
    highlight: Boolean,
  },
  computed: {
    titleBar() {
      return this.title || this.showTitleBar
    },
    uid() {
      return this._uid
    },
  },
}
</script>
<style>
.helvetica {
  font-family: 'Helvetica Neue';
}
</style>
